import React, { useEffect, useState, useRef } from "react";
import {
  Divider,
  Row,
  Col,
  Spin,
  Tabs,
  Input,
  Radio,
  message,
  Checkbox,
  Alert,
  Select,
} from "antd";
import axios from "axios";
import api from "../../../services/forteApi";
import "./StepCheckout.css";
import CardPayment from "./CardPayment";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import CreditCardInput from "react-credit-card-input";
import { isEmpty } from "utils/GlobalFunctions";
import BankPayment from "./BankPayment";
import moment from "moment";
import NormalButton from "components/ButtonWidgets/NormalButton/NormalButton";
import StepItemWidget from "../StepFee/StepItemWidget";
import { useSelector } from "react-redux";
const { TabPane } = Tabs;
const { Option } = Select;
const StepCheckoutStaxMulti = (props) => {
  const {
    appInfo,
    userData,
    setUserData,
    errorField,
    setErrorField,
    onRegister,
    recordList,
    onPaymentFail,
    regType,
    curDomain
  } = props;
  const appDataStore = useSelector((x) => x.appDataStore);
  const staxJs = useRef(null);

  const [isPayButtonDisabled, setIsPayButtonDisabled] = useState(true);

  const [isBusy, setIsBusy] = useState(false);
  const [dcAmount, setDcAmount] = useState(0);
  const [disCount, setDisCount] = useState("");
  const [codeValid, setCodeValid] = useState(true);
  const [cardType, setCardType] = useState("credit");


  useEffect(() => {
    setTimeout(() => {
      const StaxJs = window.StaxJs;
      staxJs.current = new StaxJs(process.env.REACT_APP_FM_KEY, {
        number: {
          id: "card-number",
          placeholder: "0000 0000 0000 0000",
          style:
            "width: 100%; height:100%; border-radius: 3px; border: 1px solid #ccc; padding: .5em .5em; font-size: 91%; box-sizing: border-box;",
        },
        cvv: {
          id: "card-cvv",
          placeholder: "CVV",
          style:
            "width: 100%; height:100%; border-radius: 3px; border: 1px solid #ccc; padding: .5em .5em; font-size: 91%; box-sizing: border-box;",
        },
      });

      staxJs.current.on("card_form_complete", (message) => {
        // activate pay button
        setIsPayButtonDisabled(false);
      });

      staxJs.current.on("card_form_uncomplete", (message) => {
        // deactivate pay button
        setIsPayButtonDisabled(true);
      });
    }, 200)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      showCardForm();
    }, 250)
  }, [cardType])
  const showCardForm = () =>
    staxJs.current
      .showCardForm()
      .then((handler) => { })
      .catch((err) => {
        console.log("error init form " + err);
      });

  const validateFields = () => {
    let errorList = [];
    errorList = isEmpty(userData, "bill_address", errorList);
    errorList = isEmpty(userData, "bill_city", errorList);
    errorList = isEmpty(userData, "bill_state", errorList);
    errorList = isEmpty(userData, "bill_postal_code", errorList);
    if (cardType != "echeck") {
      errorList = isEmpty(userData, "expiration", errorList);
    } else {
      errorList = isEmpty(userData, "bank_name", errorList);
      errorList = isEmpty(userData, "bank_type", errorList);
      errorList = isEmpty(userData, "bank_holder_type", errorList);
      errorList = isEmpty(userData, "bank_account", errorList);
      errorList = isEmpty(userData, "bank_routing", errorList);
    }
    setErrorField([...errorList]);
    return errorList.length == 0;
  };



  const onApply = () => {
    let codeList = [];
    if (userData?.disCountInfo) {
      codeList = [...userData?.disCountInfo];
    }
    if (disCount != "") {
      let isValid = false;
      for (let i = 0; i < codeList.length; i++) {
        if (disCount == codeList[i].discount_code) {
          isValid = true;
          break;
        } else {
          isValid = false;
        }
      }
      setCodeValid(isValid);
      if (isValid) {
        let disCountAmount = 0;
        let selDisInfo = codeList.filter((x) => x.discount_code == disCount)[0];
        if (parseInt(selDisInfo.discount_free) == 1) {
          disCountAmount = parseFloat(userData.total_amount);
        } else {
          if (parseFloat(selDisInfo.discount_percentage) > 0) {
            disCountAmount =
              (parseFloat(selDisInfo.discount_percentage) / 100) *
              userData.total_amount;
          } else if (parseFloat(selDisInfo.discount_amount) > 0) {
            disCountAmount = parseFloat(selDisInfo.discount_amount);
          } else {
            disCountAmount = 0;
          }
        }
        setDcAmount(disCountAmount);
        setTotalAmount(cardType, disCountAmount, disCount);
      }
    }
  };
  const onPay = async () => {
    if (isBusy) {
      return;
    }
    // if (regType == "true") {
    //   if (userData.camperInfo.length != recordList.length) {
    //     message.error(
    //       "You must upload the campers immunization record in order to proceed"
    //     );
    //     return;
    //   }
    // }
    if (parseFloat(userData.total_checkout) == 0) {
      setUserData({
        ...userData,
        isPayment: true,
        payment_method_id: "",
        customer_id: "",
        payment_method: cardType,
      });
      setTimeout(() => {
        onRegister(cardType);
      }, 500);
    } else {
      if (!validateFields()) {
        message.error("Please confirm payment method information");
        return;
      }

      if (cardType != "echeck" && isPayButtonDisabled) {
        message.error("Please input valid payment information");
        return;
      }
      if (userData.isPayment) {
        message.success("Payment has been already done successfully");
        setUserData({
          ...userData,
          isPayment: true,
          payment_method_id: "",
          customer_id: "",
          payment_method: cardType,
        });
        setTimeout(() => {
          onRegister(cardType);
        }, 500);
      } else {
        onPaymentCall();
      }
    }
  };
  const onChangeCardType = (e) => {
    setCardType(e.target.value);
    setTotalAmount(e.target.value, dcAmount, disCount);
  };
  const setTotalAmount = (e, amount, code) => {
    let processing_fee = 0;
    if (e == "credit") {
      processing_fee = userData.processing_credit_fee;
    } else if (e == "debit") {
      processing_fee = userData.processing_debit_fee;
    } else if (e == "echeck") {
      processing_fee = userData.processing_echeck_fee;
    }

    let countedTotal = parseFloat(
      userData.total_amount - parseFloat(amount)
    ).toFixed(2);
    if (curDomain.includes("yaccamp")) {
      if (e == "credit") {
        processing_fee = parseFloat(countedTotal * 0.032).toFixed(2);
      } else if (e == "debit") {
        processing_fee = parseFloat(countedTotal * 0.016).toFixed(2);
      } else if (e == "echeck") {
        processing_fee = 2.00;
      }
    } else {
      processing_fee = parseFloat(countedTotal * 0.0365).toFixed(2);
    }


    countedTotal = parseFloat(parseFloat(countedTotal) + parseFloat(processing_fee)).toFixed(2)
    setUserData({
      ...userData,
      discount_amount: amount,
      discount_code: code,
      total_checkout: countedTotal,
      processing_fee: processing_fee,
    });
  };
  const onPaymentCall = () => {
    if (isBusy) return;
    setIsBusy(true);
    var extraDetails = {
      total: parseFloat(userData.total_checkout).toFixed(2),
      firstname: userData.camperInfo[0].first_name,
      lastname: userData.camperInfo[0].last_name,
      email: userData.camperInfo[0].email,
      phone: "845-915-4648",
      address_1: userData.bill_address,
      address_city: userData.bill_city,
      address_state: userData.bill_state,
      address_zip: userData.bill_postal_code,
      address_country: "USA",
      send_receipt: true,
      url: "https://app.staxpayments.com/#/bill/",
      ...(cardType == "echeck"
        ? {
          method: "bank",
          bank_name: userData.bank_name,
          bank_type: userData.bank_type,
          bank_holder_type: userData.bank_holder_type,
          bank_account: userData.bank_account,
          bank_routing: userData.bank_routing,
          person_name: userData.first_name + " " + userData.last_name,
        }
        : {
          method: "card",
          month: moment(userData.expiration, "MM/YY").format("MM"),
          year: moment(userData.expiration, "MM/YY").format("YYYY"),
        }),
      validate: false,
      meta: {
        reference: appDataStore?.appInfo?.registration_info?.registration_name + " Fee",
        isCCPaymentEnabled: true,
        isACHPaymentEnabled: true,
        isTipEnabled: true,
      },
    };

    staxJs.current
      .pay(extraDetails)
      .then(async (payResult) => {
        const { payment_method_id, customer_id } = payResult;
        message.success("Payment Successful");
        setUserData({
          ...userData,
          isPayment: true,
          payment_method_id,
          customer_id,
          payment_method: cardType,
        });
        setTimeout(() => {
          setIsBusy(false);
          onRegister(cardType, payment_method_id, customer_id);
        }, 500);
      })
      .catch((err) => {
        console.log(err)

        message.error("Contact Support");
        setIsBusy(false);
        onPaymentFail(cardType, err.message, "transaction_api");
      });
  };

  return (
    <div className="profile-info-widget">
      <div>
        <span className="title" style={{ fontSize: 17 }}>
          {appInfo.registration_info?.registration_name} Fees
        </span>
        <Divider></Divider>
      </div>
      {userData.camper_reg_checked && (
        <div style={{ marginTop: 20 }} className="block">
          <span className="title" style={{ fontSize: 17 }}>
            Participant Registration
          </span>
          <Divider></Divider>
          {userData.camperInfo.map((camper, key) => {
            return (
              <div key={key}>
                <Row align={"middle"} justify={"space-between"} gutter={[16, 4]} style={{ marginTop: 10 }}>
                  <Col
                    style={{
                      fontSize: 16,
                    }}
                  >
                    Participant {key + 1}
                  </Col>
                  <Col style={{ textAlign: "end" }}>
                    ${camper.real_fee}
                  </Col>
                </Row>
              </div>
            );
          })}
        </div>
      )}

      <div style={{ marginTop: 20 }} className="block">
        <span className="title" style={{ fontSize: 17 }}>
          Extra Program Fees
        </span>
        <Divider></Divider>
        {userData.camperInfo.map((camper, key) => {
          return (
            <div key={key}>
              <div
                style={{
                  fontSize: 16,
                  marginTop: 10,
                }}
              >
                Participant {key + 1}
              </div>
              <div style={{}}>
                {userData.tripList.map((x, index) => {
                  return userData.camperInfo[key].sel_trip.includes(x.id) ? (
                    <StepItemWidget
                      itemInfo={x}
                      key={index}
                      camperIndex={key}
                      isLast={index == userData.tripList.length - 1}
                      userData={userData}
                    />
                  ) : (
                    <div key={index}></div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      <Row
        justify="space-between"
        align="middle"
        gutter={[64, 8]}
        className="discount-rect"
      >
        <Col xs={24} sm={12}>
          <div style={{ paddingBottom: "5px" }}>Discount Code</div>
          <Input
            value={disCount}
            className={`common-input`}
            onChange={(e) => {
              setDisCount(e.target.value);
            }}
          />
          <div className="discount-valid">
            {!codeValid && disCount != "" && (
              <span>Error: Discountcode Invalid</span>
            )}
          </div>
        </Col>
        <Col xs={24} sm={12}>
          <CommonButton
            style={
              disCount != ""
                ? {
                  backgroundColor: "#7fb522",
                  cursor: "pointer",
                  lineHeight: "34px",
                }
                : {
                  backgroundColor: "#878787",
                  cursor: "no-drop",
                  lineHeight: "34px",
                }
            }
            onClick={() => onApply()}
          >
            Apply
          </CommonButton>
        </Col>
      </Row>
      <div style={{ marginTop: 30 }}>
        <span className="title">Confirm Payment and Finish Checkout</span>
        <Divider></Divider>
        <div>
          <Row gutter={16} style={{ marginTop: 20 }}>
            <Col xs={24} sm={12}>
              <div style={{ paddingBottom: "5px" }}>Billing Address*</div>
              <Input
                value={userData.bill_address}
                className={
                  errorField !== undefined &&
                    errorField.includes("bill_address")
                    ? `common-input-error`
                    : `common-input`
                }
                onChange={(e) => {
                  if (errorField.includes("bill_address")) {
                    let errors = errorField.filter((x) => x != "bill_address");
                    setErrorField([...errors]);
                  }
                  setUserData({ ...userData, bill_address: e.target.value });
                }}
              />
            </Col>
            <Col xs={24} sm={12}>
              <div style={{ paddingBottom: "5px" }}>Billing City*</div>
              <Input
                value={userData.bill_city}
                className={
                  errorField !== undefined && errorField.includes("bill_city")
                    ? `common-input-error`
                    : `common-input`
                }
                onChange={(e) => {
                  if (errorField.includes("bill_city")) {
                    let errors = errorField.filter((x) => x != "bill_city");
                    setErrorField([...errors]);
                  }
                  setUserData({ ...userData, bill_city: e.target.value });
                }}
              />
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 20 }}>
            <Col xs={24} sm={12}>
              <div style={{ paddingBottom: "5px" }}>
                Billing State/Province*
              </div>
              <Select
                showSearch
                className={
                  errorField !== undefined && errorField.includes("bill_state")
                    ? `common-select-error`
                    : `common-select`
                }
                value={
                  userData.bill_state == undefined ? null : userData.bill_state
                }
                onSelect={(v) => {
                  if (errorField.includes("bill_state")) {
                    let errors = errorField.filter((x) => x != "bill_state");
                    setErrorField([...errors]);
                  }
                  setUserData({ ...userData, bill_state: v });
                }}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value="AL">Alabama</Option>
                <Option value="AK">Alaska</Option>
                <Option value="AZ">Arizona</Option>
                <Option value="AR">Arkansas</Option>
                <Option value="CA">California</Option>
                <Option value="CO">Colorado</Option>
                <Option value="CT">Connecticut</Option>
                <Option value="DE">Delaware</Option>
                <Option value="FL">Florida</Option>
                <Option value="GA">Georgia</Option>
                <Option value="HI">Hawaii</Option>
                <Option value="ID">Idaho</Option>
                <Option value="IL">Illinois</Option>
                <Option value="IN">Indiana</Option>
                <Option value="IA">Iowa</Option>
                <Option value="KS">Kansas</Option>
                <Option value="KY">Kentucky</Option>
                <Option value="LA">Louisiana</Option>
                <Option value="ME">Maine</Option>
                <Option value="MD">Maryland</Option>
                <Option value="MA">Massachusetts</Option>
                <Option value="MI">Michigan</Option>
                <Option value="MN">Minnesota</Option>
                <Option value="MS">Mississippi</Option>
                <Option value="MO">Missouri</Option>
                <Option value="MT">Montana</Option>
                <Option value="NE">Nebraska</Option>
                <Option value="NV">Nevada</Option>
                <Option value="NH">New Hampshire</Option>
                <Option value="NJ">New Jersey</Option>
                <Option value="NM">New Mexico</Option>
                <Option value="NY">New York</Option>
                <Option value="NC">North Carolina</Option>
                <Option value="ND">North Dakota</Option>
                <Option value="OH">Ohio</Option>
                <Option value="OK">Oklahoma</Option>
                <Option value="OR">Oregon</Option>
                <Option value="PA">Pennsylvania</Option>
                <Option value="RI">Rhode Island</Option>
                <Option value="SC">South Carolina</Option>
                <Option value="SD">South Dakota</Option>
                <Option value="TN">Tennessee</Option>
                <Option value="TX">Texas</Option>
                <Option value="UT">Utah</Option>
                <Option value="VT">Vermont</Option>
                <Option value="VA">Virginia</Option>
                <Option value="WA">Washington</Option>
                <Option value="WV">West Virginia</Option>
                <Option value="WI">Wisconsin</Option>
                <Option value="WY">Wyoming</Option>
              </Select>
            </Col>
            <Col xs={24} sm={12}>
              <div style={{ paddingBottom: "5px" }}>
                Billing Zip/Postal Code*
              </div>
              <Input
                value={userData.bill_postal_code}
                className={
                  errorField !== undefined &&
                    errorField.includes("bill_postal_code")
                    ? `common-input-error`
                    : `common-input`
                }
                onChange={(e) => {
                  if (errorField.includes("bill_postal_code")) {
                    let errors = errorField.filter(
                      (x) => x != "bill_postal_code"
                    );
                    setErrorField([...errors]);
                  }
                  setUserData({
                    ...userData,
                    bill_postal_code: e.target.value,
                  });
                }}
              />
            </Col>
          </Row>
        </div>
        {/* <div style={{ marginTop: 20, marginLeft: 20 }}>
          <span
            style={{ fontWeight: 400 }}
          >{`Credit Card (VISA, Mastercard, AMEX, Discover)`}</span>
        </div> */}
        <div style={{ marginTop: 20, marginLeft: 20 }}>
          <Radio.Group
            onChange={(e) => {
              onChangeCardType(e);
            }}
            value={cardType}
          >
            <Radio value={"credit"}>Credit Card(3.2%)</Radio>
            <Radio value={"debit"}>Debit Card(1.6%)</Radio>
          </Radio.Group>
        </div>
      </div>
      <div className="block">
        {cardType !== "echeck" && <CardPayment
          userData={userData}
          setUserData={setUserData}
          errorField={errorField}
          setErrorField={setErrorField}
        />}
        {cardType == "echeck" && <BankPayment
          userData={userData}
          setUserData={setUserData}
          errorField={errorField}
          setErrorField={setErrorField}
        />}
      </div>
      <div style={{ marginTop: 30 }}>
        <span className="title">Order Summary</span>
        <Divider></Divider>
      </div>
      <div className="block">
        <Row
          style={{
            marginTop: 10,
            paddingLeft: 20,
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          <Col flex={"auto"}>Registration Fees</Col>
          <Col flex={"180px"}>${userData.src_total_amount}</Col>
        </Row>
        {codeValid && disCount != "" && (
          <Row
            style={{
              marginTop: 20,
              paddingLeft: 20,
              paddingTop: 10,
              paddingBottom: 10,
              color: "var(--redColor)",
            }}
          >
            <Col flex={"auto"}>Registration Discount</Col>
            <Col flex={"185px"}>-${userData.discount_amount}</Col>
          </Row>
        )}
        <Row
          style={{
            marginTop: 20,
            paddingLeft: 20,
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          <Col flex={"auto"}>Processing Fees</Col>
          <Col flex={"180px"}>${userData.processing_fee}</Col>
        </Row>
        <Row
          style={{
            marginTop: 20,
            background: "var(--mainColor)",
            padding: 20,
            color: "var(--whiteColor)",
          }}
          justify={"center"}
        >
          <Col flex={"auto"}>Checkout Total</Col>
          <Col flex={"180px"} style={{ paddingLeft: 20, fontSize: 16 }}>
            ${userData.total_checkout}
          </Col>
        </Row>
      </div>
      <div style={{ marginTop: 50, marginBottom: 30, textAlign: "center" }}>
        <NormalButton
          style={{ backgroundColor: "#7fb522", cursor: "pointer" }}
          onClick={() => {
            onPay();
          }}
          isBusy={isBusy}
        >
          {parseFloat(userData.total_checkout) == 0
            ? "Register"
            : "Pay $" + userData.total_checkout}
        </NormalButton>
      </div>
    </div>
  );
};

export default StepCheckoutStaxMulti;
