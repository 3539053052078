import React, { useState } from "react";
import { Row, Col, Input, Divider, Select, InputNumber, message } from "antd";
import "./StepOne.css";
import { CloseOutlined } from "@ant-design/icons";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";

const StepOne = (props) => {
  const {
    userData,
    setUserData,
    errorField,
    setErrorField,
    recordList,
    setRecordList,
    regType,
    curDomain
  } = props;
  const { Option } = Select;
  const onDropImage = (e, index) => {
    if (errorField.includes(index + "record")) {
      let errors = errorField.filter((y) => y != index + "record");
      setErrorField([...errors]);
    }
    const files = Array.from(e.target.files);
    if (files.length === 0) return;
    if (files[0].size / 1000 / 1000 > 5) {
      message.error("File size should not be greater than 5Mb.");
      return;
    }
    let tmpCampList = [...recordList];
    tmpCampList[index] = files[0];
    setRecordList(tmpCampList);
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const onAddMore = () => {
    let tmpList = [...userData.camperInfo];
    let newInfo = {
      first_name: "",
      last_name: "",
      age: "",
      grade: "",
      street: tmpList[0].street,
      city: tmpList[0].city,
      state: tmpList[0].state,
      zip: tmpList[0].zip,
      t_size: "",
      health: "",
      sel_trip: "",
    };
    tmpList = [...tmpList, newInfo];
    setUserData({
      ...userData,
      camperInfo: tmpList,
    });
    let tmpRecordList = [...recordList];
    setRecordList([...tmpRecordList, {}]);
  };
  const onDelPlayer = (index) => {
    let tmpList = [...userData.camperInfo];
    tmpList = tmpList.filter((x, k) => k !== index);

    setUserData({
      ...userData,
      camperInfo: tmpList,
    });
    let tmpRecordList = [...recordList];
    tmpRecordList = tmpRecordList.filter((x, k) => k !== index);
    setRecordList(tmpRecordList);
  };
  return (
    <div className="profile-info-widget">
      <div>
        <span className="title">Enter Participant Info</span>
        <Divider></Divider>
      </div>
      <div className="block">
        {userData.camperInfo.map((x, index) => {
          return (
            <div key={index}>
              <div>
                <Row justify="space-between">
                  <Col>
                    <span className="title">Participant {index + 1} Info</span>
                  </Col>
                  <Col>
                    <span
                      className="title"
                      onClick={() => {
                        onDelPlayer(index);
                      }}
                      style={
                        index == 0 ? { display: "none" } : { cursor: "pointer" }
                      }
                    >
                      Close
                    </span>
                  </Col>
                </Row>
                <Divider
                  style={{ borderTop: "1px solid rgb(151, 196, 217, 71%)" }}
                ></Divider>
              </div>
              <div style={{ marginTop: 20, marginBottom: 20 }}>
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={12}>
                    <div style={{ paddingBottom: "5px" }}>
                      Participant First Name*
                    </div>
                    <Input
                      value={x.first_name}
                      className={
                        errorField !== undefined &&
                          errorField.includes(index + "first_name")
                          ? `common-input-error`
                          : `common-input`
                      }
                      onChange={(e) => {
                        if (errorField.includes(index + "first_name")) {
                          let errors = errorField.filter(
                            (y) => y != index + "first_name"
                          );
                          setErrorField([...errors]);
                        }
                        let tmpCampList = [...userData.camperInfo];
                        tmpCampList[index].first_name = capitalizeFirstLetter(
                          e.target.value
                        );
                        setUserData({
                          ...userData,
                          camperInfo: tmpCampList,
                        });
                      }}
                    />
                  </Col>
                  <Col xs={24} sm={12}>
                    <div style={{ paddingBottom: "5px" }}>
                      Participant Last Name*
                    </div>
                    <Input
                      value={x.last_name}
                      className={
                        errorField !== undefined &&
                          errorField.includes(index + "last_name")
                          ? `common-input-error`
                          : `common-input`
                      }
                      onChange={(e) => {
                        if (errorField.includes(index + "last_name")) {
                          let errors = errorField.filter(
                            (y) => y != index + "last_name"
                          );
                          setErrorField([...errors]);
                        }
                        let tmpCampList = [...userData.camperInfo];
                        tmpCampList[index].last_name = capitalizeFirstLetter(
                          e.target.value
                        );
                        setUserData({
                          ...userData,
                          camperInfo: tmpCampList,
                        });
                      }}
                    />
                  </Col>
                  <Col xs={24} sm={12}>
                    <div style={{ paddingBottom: "5px" }}>Participant Email*</div>
                    <Input
                      type={"email"}
                      value={x.email}
                      className={
                        errorField !== undefined &&
                          errorField.includes(index + "email")
                          ? `common-input-error`
                          : `common-input`
                      }
                      onChange={(e) => {
                        if (errorField.includes(index + "email")) {
                          let errors = errorField.filter(
                            (y) => y != index + "email"
                          );
                          setErrorField([...errors]);
                        }
                        let tmpCampList = [...userData.camperInfo];
                        tmpCampList[index].email = e.target.value
                        setUserData({
                          ...userData,
                          camperInfo: tmpCampList,
                        });
                      }}
                    />
                  </Col>
                  <Col xs={24} sm={12}>
                    <div style={{ paddingBottom: "5px" }}>
                      Participant Gender*
                    </div>
                    <Select
                      className={
                        errorField !== undefined &&
                          errorField.includes(index + "gender")
                          ? `common-select-error`
                          : `common-select`
                      }
                      value={x.gender == undefined ? null : x.gender}
                      onSelect={(v) => {
                        if (errorField.includes(index + "gender")) {
                          let errors = errorField.filter(
                            (y) => y != index + "gender"
                          );
                          setErrorField([...errors]);
                        }
                        let tmpCampList = [...userData.camperInfo];
                        tmpCampList[index].gender = v;
                        setUserData({
                          ...userData,
                          camperInfo: tmpCampList,
                        });
                      }}
                    >
                      <Option value="Male">Male</Option>
                      <Option value="Female">Female</Option>
                    </Select>
                  </Col>
                  <Col xs={24} sm={12}>
                    <div style={{ paddingBottom: "5px" }}>Participant Age* (on day of race)</div>
                    <InputNumber
                      min={1}
                      max={100}
                      size="small"
                      value={x.age}
                      className={
                        errorField !== undefined &&
                          errorField.includes(index + "age")
                          ? `common-input-error`
                          : `common-input`
                      }
                      onChange={(e) => {
                        if (errorField.includes(index + "age")) {
                          let errors = errorField.filter(
                            (y) => y != index + "age"
                          );
                          setErrorField([...errors]);
                        }
                        let tmpCampList = [...userData.camperInfo];
                        tmpCampList[index].age = e;
                        setUserData({
                          ...userData,
                          camperInfo: tmpCampList,
                        });
                      }}
                      step={1}
                    />
                  </Col>
                  <Col xs={24} sm={12}>
                    <div style={{ paddingBottom: "5px" }}>
                      T-Shirt Size*
                    </div>
                    <Select
                      className={
                        errorField !== undefined &&
                          errorField.includes(index + "t_size")
                          ? `common-select-error`
                          : `common-select`
                      }
                      value={x.t_size == undefined ? null : x.t_size}
                      onSelect={(v) => {
                        if (errorField.includes(index + "t_size")) {
                          let errors = errorField.filter(
                            (y) => y != index + "t_size"
                          );
                          setErrorField([...errors]);
                        }
                        let tmpCampList = [...userData.camperInfo];
                        tmpCampList[index].t_size = v;
                        setUserData({
                          ...userData,
                          camperInfo: tmpCampList,
                        });
                      }}
                    >
                      {/* <Option value="YS">YS</Option> */}
                      <Option value="YM">YM</Option>
                      <Option value="YL">YL</Option>
                      {/* <Option value="YXL">YXL</Option> */}
                      <Option value="AS">AS</Option>
                      <Option value="AM">AM</Option>
                      <Option value="AL">AL</Option>
                      <Option value="AXL">AXL</Option>
                      <Option value="AXXL">AXXL</Option>
                    </Select>
                  </Col>
                </Row>
                {regType == "true" && (
                  <>
                    <Row gutter={16} style={{ marginTop: 20 }}>

                      {/* <Col xs={24} sm={12}>
                        <div style={{ paddingBottom: "5px" }}>
                          {curDomain.includes("yaccamp") ? `Participant Grade as of June 2024*` : `Participant Grade in September*`}
                        </div>
                        <Select
                          className={
                            errorField !== undefined &&
                              errorField.includes(index + "grade")
                              ? `common-select-error`
                              : `common-select`
                          }
                          value={x.grade == undefined ? null : x.grade}
                          onSelect={(v) => {
                            if (errorField.includes(index + "grade")) {
                              let errors = errorField.filter(
                                (y) => y != index + "grade"
                              );
                              setErrorField([...errors]);
                            }
                            let tmpCampList = [...userData.camperInfo];
                            tmpCampList[index].grade = v;
                            setUserData({
                              ...userData,
                              camperInfo: tmpCampList,
                            });
                          }}
                        >
                          <Option value="0">K</Option>
                          <Option value="1">1</Option>
                          <Option value="2">2</Option>
                          <Option value="3">3</Option>
                          <Option value="4">4</Option>
                          <Option value="5">5</Option>
                          <Option value="6">6</Option>
                          <Option value="7">7</Option>
                          <Option value="8">8</Option>
                          <Option value="9">9</Option>
                          <Option value="10">10</Option>
                          <Option value="11">11</Option>
                          <Option value="12">12</Option>
                        </Select>
                      </Col> */}
                    </Row>

                    <Row gutter={16} style={{ marginTop: 10 }}>
                      <Col xs={24} sm={12}>
                        <div style={{ paddingBottom: "5px" }}>
                          Participant Street Address*
                        </div>
                        <Input
                          value={x.street}
                          className={
                            errorField !== undefined &&
                              errorField.includes(index + "street")
                              ? `common-input-error`
                              : `common-input`
                          }
                          onChange={(e) => {
                            if (errorField.includes(index + "street")) {
                              let errors = errorField.filter(
                                (y) => y != index + "street"
                              );
                              setErrorField([...errors]);
                            }
                            let tmpCampList = [...userData.camperInfo];
                            tmpCampList[index].street = e.target.value;
                            setUserData({
                              ...userData,
                              camperInfo: tmpCampList,
                            });
                          }}
                        />
                      </Col>
                      <Col xs={24} sm={12}>
                        <div style={{ paddingBottom: "5px" }}>Participant City*</div>
                        <Input
                          value={x.city}
                          className={
                            errorField !== undefined &&
                              errorField.includes(index + "city")
                              ? `common-input-error`
                              : `common-input`
                          }
                          onChange={(e) => {
                            if (errorField.includes(index + "city")) {
                              let errors = errorField.filter(
                                (y) => y != index + "city"
                              );
                              setErrorField([...errors]);
                            }
                            let tmpCampList = [...userData.camperInfo];
                            tmpCampList[index].city = e.target.value;
                            setUserData({
                              ...userData,
                              camperInfo: tmpCampList,
                            });
                          }}
                        />
                      </Col>
                    </Row>
                    <Row gutter={16} style={{ marginTop: 20 }}>
                      <Col xs={24} sm={12}>
                        <div style={{ paddingBottom: "5px" }}>
                          Participant State/Province*
                        </div>
                        <Select
                          showSearch
                          className={
                            errorField !== undefined &&
                              errorField.includes(index + "state")
                              ? `common-select-error`
                              : `common-select`
                          }
                          value={x.state}
                          onSelect={(v) => {
                            if (errorField.includes(index + "state")) {
                              let errors = errorField.filter(
                                (y) => y != index + "state"
                              );
                              setErrorField([...errors]);
                            }
                            let tmpCampList = [...userData.camperInfo];
                            tmpCampList[index].state = v;
                            setUserData({
                              ...userData,
                              camperInfo: tmpCampList,
                            });
                          }}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          <Option value="AL">Alabama</Option>
                          <Option value="AK">Alaska</Option>
                          <Option value="AZ">Arizona</Option>
                          <Option value="AR">Arkansas</Option>
                          <Option value="CA">California</Option>
                          <Option value="CO">Colorado</Option>
                          <Option value="CT">Connecticut</Option>
                          <Option value="DE">Delaware</Option>
                          <Option value="FL">Florida</Option>
                          <Option value="GA">Georgia</Option>
                          <Option value="HI">Hawaii</Option>
                          <Option value="ID">Idaho</Option>
                          <Option value="IL">Illinois</Option>
                          <Option value="IN">Indiana</Option>
                          <Option value="IA">Iowa</Option>
                          <Option value="KS">Kansas</Option>
                          <Option value="KY">Kentucky</Option>
                          <Option value="LA">Louisiana</Option>
                          <Option value="ME">Maine</Option>
                          <Option value="MD">Maryland</Option>
                          <Option value="MA">Massachusetts</Option>
                          <Option value="MI">Michigan</Option>
                          <Option value="MN">Minnesota</Option>
                          <Option value="MS">Mississippi</Option>
                          <Option value="MO">Missouri</Option>
                          <Option value="MT">Montana</Option>
                          <Option value="NE">Nebraska</Option>
                          <Option value="NV">Nevada</Option>
                          <Option value="NH">New Hampshire</Option>
                          <Option value="NJ">New Jersey</Option>
                          <Option value="NM">New Mexico</Option>
                          <Option value="NY">New York</Option>
                          <Option value="NC">North Carolina</Option>
                          <Option value="ND">North Dakota</Option>
                          <Option value="OH">Ohio</Option>
                          <Option value="OK">Oklahoma</Option>
                          <Option value="OR">Oregon</Option>
                          <Option value="PA">Pennsylvania</Option>
                          <Option value="RI">Rhode Island</Option>
                          <Option value="SC">South Carolina</Option>
                          <Option value="SD">South Dakota</Option>
                          <Option value="TN">Tennessee</Option>
                          <Option value="TX">Texas</Option>
                          <Option value="UT">Utah</Option>
                          <Option value="VT">Vermont</Option>
                          <Option value="VA">Virginia</Option>
                          <Option value="WA">Washington</Option>
                          <Option value="WV">West Virginia</Option>
                          <Option value="WI">Wisconsin</Option>
                          <Option value="WY">Wyoming</Option>
                        </Select>
                      </Col>
                      <Col xs={24} sm={12}>
                        <div style={{ paddingBottom: "5px" }}>
                          Participant Zip Code*
                        </div>
                        <Input
                          value={x.zip}
                          maxLength={5}
                          className={
                            errorField !== undefined &&
                              errorField.includes(index + "zip")
                              ? `common-input-error`
                              : `common-input`
                          }
                          onChange={(e) => {
                            if (errorField.includes(index + "zip")) {
                              let errors = errorField.filter(
                                (y) => y != index + "zip"
                              );
                              setErrorField([...errors]);
                            }
                            let tmpCampList = [...userData.camperInfo];
                            tmpCampList[index].zip = e.target.value;
                            setUserData({
                              ...userData,
                              camperInfo: tmpCampList,
                            });
                          }}
                        />
                      </Col>
                    </Row>
                    <Row gutter={16} style={{ marginTop: 20 }}>

                      {/* <Col xs={24} sm={12}>
                        <div style={{ paddingBottom: "5px" }}>
                          List of known allergies, health or behavioral concerns*
                        </div>
                        <Input
                          value={x.health}
                          className={
                            errorField !== undefined &&
                              errorField.includes(index + "health")
                              ? `common-input-error`
                              : `common-input`
                          }
                          placeholder='Enter "None" if none'
                          onChange={(e) => {
                            if (errorField.includes(index + "health")) {
                              let errors = errorField.filter(
                                (y) => y != index + "health"
                              );
                              setErrorField([...errors]);
                            }
                            let tmpCampList = [...userData.camperInfo];
                            tmpCampList[index].health = e.target.value;
                            setUserData({
                              ...userData,
                              camperInfo: tmpCampList,
                            });
                          }}
                        />
                      </Col> */}
                    </Row>
                    {/* <div
                      style={{
                        marginTop: 15,
                        color: "var(--redColor)",
                      }}
                    >
                      PLEASE NOTE: IMMUNIZATION RECORDS MUST BE UPLOADED IN
                      ORDER TO PROCEED WITH THIS REGISTRATION
                    </div>
                    <div style={{ marginTop: 5, marginBottom: 15 }}>
                      Click the Immunization Record button below to upload
                      (files must be less than 5MB in size)
                    </div>
                    <div>
                      <Row gutter={[4, 8]}>
                        <Col>
                          <label htmlFor={"new_image_uploader" + index}>
                            <input
                              type="file"
                              id={"new_image_uploader" + index}
                              name={"new_image_uploader" + index}
                              style={{ display: "none" }}
                              accept="*"
                              onChange={(e) => {
                                onDropImage(e, index);
                              }}
                            />
                            <span className="custom-button common-button ">
                              Immunization Record*
                            </span>
                          </label>
                        </Col>
                        <Col>
                          {recordList[index]?.name && (
                            <span style={{ paddingLeft: 20 }}>
                              {recordList[index]?.name}
                            </span>
                          )}
                        </Col>
                      </Row>

                    </div>
                    {errorField !== undefined &&
                      errorField.includes(index + "record") && (
                        <div style={{ marginTop: 10 }}>
                          <span style={{ color: "var(--redColor)" }}>
                            You must upload the campers immunization record in
                            order to proceed
                          </span>
                        </div>
                      )} */}
                  </>
                )}
              </div>
            </div>
          );
        })}
        <div style={{ marginTop: 40, textAlign: "center", marginBottom: 10 }}>
          <CommonButton
            customClass="register-next-button"
            onClick={() => {
              onAddMore();
            }}
          >
            Add another Racer
          </CommonButton>
        </div>
      </div>
    </div>
  );
};

export default StepOne;
